export default [
  // level 0:模块 1:菜单 2:tab 3：按钮
  {
    path: "global",
    name: "全局设置",
    children: [
      {
        path: "global-localChange",
        name: "内/外网切换",
        level: 3,
      },
    ],
  },
  {
    path: "/index",
    pathName: "index",
    name: "首页",
    img: require("@/assets/images/menu_icon/index.png"),
    level: 1,
  },
  {
    path: "/bigScreen",
    pathName: "bigScreen",
    name: "数据大屏",
    img: require("@/assets/images/menu_icon/bigScreen.png"),
    level: 1,
    children: [
      {
        path: "/bigScreen/sales",
        name: "运营数据",
        level: 2,
      },
      {
        path: "/bigScreen/salesStatistics",
        name: "运营额统计",
        level: 2,
      },
      {
        path: "/bigScreen/parkingRecord",
        name: "停车记录",
        level: 2,
      },
      {
        path: "/bigScreen/parkingInfo",
        name: "停车场信息",
        level: 2,
      },
      {
        path: "/bigScreen/paymentMethod",
        name: "支付方式",
        level: 2,
      },
      {
        path: "/bigScreen/LeasedCarsPercentage",
        name: "租赁车占比率",
        level: 2,
      },
    ],
  },
  {
    path: "/gangting-bigScreen",
    pathName: "gangting-bigScreen",
    name: "岗亭大屏",
    img: require("@/assets/images/menu_icon/gangting-bigScreen.png"),
    level: 1,
    children: [
      {
        path: "gangting-bigScreen-video",
        name: "监控播放",
        level: 3,
      },
      {
        path: "gangting-bigScreen-manual",
        name: "模拟出/入场",
        level: 3,
      },
      {
        path: "gangting-bigScreen-remain-revise",
        name: "修改剩余车位",
        level: 3,
      },
    ],
  },
  {
    path: "/report",
    name: "统计报表",
    img: require("@/assets/images/menu_icon/report.png"),
    level: 0,
    children: [
      {
        path: "/report/parkingDensity",
        name: "停车场景密度分析",
        pathName: "report-parkingDensity",
        level: 1,
      },
      {
        path: "/report/userPreference",
        name: "用户喜好逻辑分析",
        pathName: "report-userPreference",
        level: 1,
      },
      {
        path: "/report/businessCircle",
        name: "商圈繁荣层次分析",
        pathName: "report-businessCircle",
        level: 1,
      },
      {
        path: "/report/integratedManagement",
        name: "多维集成管理分析",
        pathName: "report-integratedManagement",
        level: 1,
      },
      {
        path: "/report/drainagePerformance",
        name: "车辆引流绩效分析",
        pathName: "report-drainagePerformance",
        level: 1,
      },
      {
        path: "/report/CNCEpidemicPrevention",
        name: "数控防疫追溯分析",
        pathName: "report-CNCEpidemicPrevention",
        level: 1,
      },
      {
        path: "/report/carTravel",
        name: "车载出行绩效分析",
        pathName: "report-carTravel",
        level: 1,
      },
      {
        path: "/report/signalReturn",
        name: "碎片信号回流分析",
        pathName: "report-signalReturn",
        level: 1,
      },
    ],
  },
  {
    path: "/passageway",
    name: "停车记录管理",
    img: require("@/assets/images/menu_icon/device.png"),
    level: 0,
    children: [
      {
        path: "/passageway/list",
        name: "出入口列表",
        pathName: "passagewayList",
        level: 1,
        children: [
          {
            path: "passagewayList-addPassageway",
            name: "功能按钮-新建出入口",
            level: 3,
          },
          { path: "passagewayList-openBtn", name: "列表操作-开闸", level: 3 },
          { path: "passagewayList-delBtn", name: "列表操作-删除", level: 3 },
          {
            path: "passagewayList-checkMonitor",
            name: "列表操作-查看监控",
            level: 3,
          },
          {
            path: "passagewayList-detail",
            pathName: "passagewayDetail",
            name: "列表操作-查看详情",
            level: 3,
            children: [
              {
                path: "passagewayList-detail-infoEdit_channelName",
                name: "基础信息-修改出入口名称",
                level: 3,
              },
              {
                path: "passagewayList-detail-infoEdit_channelAddress",
                name: "基础信息-修改出入口位置",
                level: 3,
              },
              {
                path: "passagewayList-detail-infoEdit_channelType",
                name: "基础信息-修改出入口类型",
                level: 3,
              },
              {
                path: "passagewayList-detail-infoEdit_parkingLotName",
                name: "基础信息-修改停车场",
                level: 3,
              },
              {
                path: "passagewayList-detail-infoEdit_whiteEnable",
                name: "基础信息-修改启用黑白名单",
                level: 3,
              },
              {
                path: "passagewayList-detail-infoEdit_soundVolume",
                name: "基础信息-修改播报音量",
                level: 3,
              },
              {
                path: "passagewayList-detail-infoEdit_parkingAisle",
                name: "基础信息-修改过场道闸",
                level: 3,
              },
              {
                path: "passagewayList-detail-infoEdit_disableIccIssue",
                name: "基础信息-修改是否主动注册",
                level: 3,
              },
              {
                path: "passagewayList-detail-infoEdit_leaseOnly",
                name: "基础信息-仅租赁车可进",
                level: 3,
              },
              {
                path: "passagewayList-detail-openBtn",
                name: "功能按钮-开闸",
                level: 3,
              },
              {
                path: "passagewayList-detail-passagewayTab",
                name: "标签页-出入口记录",
                level: 2,
                children: [
                  {
                    path: "passagewayList-detail-passagewayTab-getImg",
                    name: "列表操作-获取图片",
                    level: 3,
                  },
                ],
              },
              {
                path: "passagewayList-detail-malfunctionTab",
                name: "标签页-故障记录",
                level: 2,
              },
              {
                path: "passagewayList-detail-deviceTab",
                name: "标签页-设备列表",
                level: 2,
                children: [
                  {
                    path: "passagewayList-detail-deviceTab-addDevice",
                    name: "功能按钮-添加设备",
                    level: 3,
                  },
                  {
                    path: "passagewayList-detail-deviceTab-checkMonitor",
                    name: "功能按钮-查看监控",
                    level: 3,
                  },
                  {
                    path: "passagewayList-detail-deviceTab-editBtn",
                    name: "列表操作-编辑",
                    level: 3,
                  },
                  {
                    path: "passagewayList-detail-deviceTab-delBtn",
                    name: "列表操作-删除",
                    level: 3,
                  },
                ],
              },
              {
                path: "passagewayList-detail-deviceRegisterTab",
                name: "标签页-设备列表(主动注册)",
                level: 2,
                children: [
                  {
                    path: "passagewayList-detail-deviceRegisterTab-checkMonitor",
                    name: "列表操作-查看监控",
                    level: 3,
                  },
                ],
              },
              {
                path: "passagewayList-detail-whiteListTab",
                name: "标签页-白名单",
                level: 2,
              },
            ],
          },
        ],
      },
      {
        path: "/passageway/record",
        name: "出入口记录",
        pathName: "passagewayRecord",
        level: 1,
        children: [
          {
            path: "passagewayRecord-getImg",
            name: "列表操作-获取图片",
            level: 3,
          },
        ],
      },
      {
        path: "/passageway/parkingRecord",
        name: "停车记录列表",
        pathName: "parkingRecord",
        level: 1,
        children: [
          {
            path: "parkingRecord-export",
            name: "功能按钮-导出报表",
            level: 3,
          },
          {
            path: "parkingRecord-recordDetail",
            name: "列表操作-记录详情",
            level: 3,
          },
          {
            path: "parkingRecord-orderParkingInfo",
            name: "列表操作-订单明细",
            level: 3,
          },
          {
            path: "parkingRecord-order",
            name: "列表操作-关联订单",
            level: 3,
          },
        ],
      },
      {
        path: "/passageway/parkingGroupRecord",
        name: "联合停车场记录",
        pathName: "parkingGroupRecord",
        level: 1,
      },
      {
        path: "/passageway/openingList",
        name: "手动开闸列表",
        pathName: "openingList",
        level: 1,
        children: [
          {
            path: "passageway-openingList-export",
            name: "功能按钮-导出报表",
            level: 3,
          },
        ],
      },
      {
        path: "/passageway/chargeRecord",
        name: "充电记录列表",
        pathName: "chargeRecord",
        level: 1,
      },
    ],
  },
  {
    path: "/vehicle",
    name: "车辆管理",
    img: require("@/assets/images/menu_icon/vehicle.png"),
    level: 0,
    children: [
      {
        path: "/vehicle/list",
        pathName: "vehicleList",
        name: "车辆列表",
        level: 1,
        children: [
          {
            path: "vehicleList-addVehicle",
            name: "功能按钮-新建车辆",
            level: 3,
          },
          {
            path: "vehicleList-detail",
            pathName: "vehicleDetail",
            name: "列表操作-查看详情",
            level: 3,
            children: [
              {
                path: "vehicleList-detail-passagewayTab",
                name: "标签页-出入场记录",
                level: 2,
              },
              {
                path: "vehicleList-detail-bindingPeopleTab",
                name: "标签页-绑定人",
                level: 2,
                children: [
                  {
                    path: "vehicleList-detail-bindingPeopleTab-detail",
                    name: "列表操作-查看详情",
                    level: 3,
                  },
                ],
              },
              {
                path: "vehicleList-detail-setmealTab",
                name: "标签页-套餐列表",
                level: 2,
                children: [
                  {
                    path: "vehicleList-detail-setmealTab-bindSetmeal",
                    name: "功能按钮-绑定套餐",
                    level: 3,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/parkingLot",
    name: "停车场管理",
    img: require("@/assets/images/menu_icon/parkingLot.png"),
    level: 0,
    children: [
      {
        path: "/parkingLot/list",
        pathName: "parkingLotList",
        name: "停车场列表",
        level: 1,
        children: [
          {
            path: "parkingLotList-addParkingLot",
            name: "功能按钮-新建停车场",
            level: 3,
          },
          {
            path: "parkingLotList-detail",
            pathName: "parkingLotDetail",
            name: "列表操作-查看详情",
            level: 3,
            children: [
              {
                path: "parkingLotList-detail-info_parkingLotName",
                name: "基础信息-修改停车场名称",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_parkingLotAddress",
                name: "基础信息-修改停车场地址",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_coord",
                name: "基础信息-修改点位坐标",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_totalLots",
                name: "基础信息-修改总停车位",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_idleLots",
                name: "基础信息-修改剩余车位",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_property",
                name: "基础信息-修改产权车位",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_civilDefense",
                name: "基础信息-修改人防车位",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_parkingLotType",
                name: "基础信息-修改停车场类型",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_subjectType",
                name: "基础信息-修改主体类型",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_businessCircleId",
                name: "基础信息-修改商圈",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_advertisingImg",
                name: "基础信息-修改广告图片",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_autoOpen",
                name: "基础信息-修改离场开闸",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_interestRate",
                name: "基础信息-修改利率",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_taxRate",
                name: "基础信息-修改开票税点",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_parkingGroupId",
                name: "基础信息-修改联合停车场",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_shopId",
                name: "基础信息-修改商户号",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_staffId",
                name: "基础信息-修改门店号",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_couponFlag",
                name: "基础信息-修改优惠券核销",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_freeCarFlag",
                name: "基础信息-修改挂单车核销",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_ignoreRemain",
                name: "基础信息-修改忽略余位",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_ignoreLease",
                name: "基础信息-租赁车不占位",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_leaseOnly",
                name: "基础信息-修改仅租赁车可进",
                level: 3,
              },
              {
                path: "parkingLotList-detail-info_account",
                name: "基础信息-修改第三方平台对接",
                level: 3,
              },
              {
                path: "parkingLotList-detail-invoiceInfo_bind",
                name: "开票信息-绑定",
                level: 3,
              },
              {
                path: "parkingLotList-detail-invoiceInfo_unBind",
                name: "开票信息-解绑",
                level: 3,
              },
              {
                path: "parkingLotList-detail-passagewayTab",
                name: "标签页-出入口列表",
                level: 2,
                children: [
                  {
                    path: "parkingLotList-detail-passagewayTab-detail",
                    name: "列表操作-查看详情",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-passagewayTab-openBtn",
                    name: "列表操作-开闸",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-passagewayTab-exitCode",
                    name: "列表操作-出口码下载",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-passagewayTab-unlicensedCode",
                    name: "列表操作-无牌码下载",
                    level: 3,
                  },
                ],
              },
              {
                path: "parkingLotList-detail-associatedParkingTab",
                name: "标签页-关联停车场",
                level: 2,
                children: [
                  {
                    path: "parkingLotList-detail-associatedParkingTab-add",
                    name: "列表操作-新增",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-associatedParkingTab-edit",
                    name: "功能按钮-编辑",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-associatedParkingTab-delete",
                    name: "功能按钮-删除",
                    level: 3,
                  },
                ],
              },
              {
                path: "parkingLotList-detail-payRecordTab",
                level: 2,
                name: "标签页-缴费记录",
              },
              {
                path: "parkingLotList-detail-venueVehicleTab",
                level: 2,
                name: "标签页-场内车辆",
                children: [
                  {
                    path: "parkingLotList-detail-venueVehicleTab-export",
                    name: "功能按钮-报表导出",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-venueVehicleTab-batchForcedOut",
                    name: "功能按钮-批量出场",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-venueVehicleTab-forcedOut",
                    name: "列表操作-强制出场",
                    level: 3,
                  },
                ],
              },
              {
                path: "parkingLotList-detail-ratesStandardTab",
                name: "标签页-收费标准",
                level: 2,
                children: [
                  {
                    path: "parkingLotList-detail-ratesStandardTab-normal_addBtn",
                    name: "正常-添加",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-ratesStandardTab-normal_calculation",
                    name: "正常-计算",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-ratesStandardTab-normal_editBtn",
                    name: "正常-编辑",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-ratesStandardTab-normal_delBtn",
                    name: "正常-删除",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-ratesStandardTab-owner_addBtn",
                    name: "业主-添加",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-ratesStandardTab-owner_calculation",
                    name: "业主-计算",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-ratesStandardTab-owner_editBtn",
                    name: "业主-编辑",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-ratesStandardTab-owner_delBtn",
                    name: "业主-删除",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-ratesStandardTab-charge_addBtn",
                    name: "充电-添加",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-ratesStandardTab-charge_calculation",
                    name: "充电-计算",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-ratesStandardTab-charge_editBtn",
                    name: "充电-编辑",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-ratesStandardTab-charge_delBtn",
                    name: "充电-删除",
                    level: 3,
                  },
                ],
              },
              {
                path: "parkingLotList-detail-temporaryChargesTab",
                name: "标签页-充电收费",
                level: 2,
                children: [
                  {
                    path: "parkingLotList-detail-temporaryChargesTab-addBtn",
                    name: "规则-添加",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-temporaryChargesTab-calculation",
                    name: "规则-计算",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-temporaryChargesTab-editBtn",
                    name: "规则-编辑",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-temporaryChargesTab-delBtn",
                    name: "规则-删除",
                    level: 3,
                  },
                ],
              },
              {
                path: "parkingLotList-detail-vehicleListTab",
                name: "标签页-车辆列表",
                level: 2,
                children: [
                  {
                    path: "parkingLotList-detail-vehicleListTab-add",
                    name: "功能按钮-新建车辆",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-vehicleListTab-import",
                    name: "功能按钮-导入",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-vehicleListTab-export",
                    name: "功能按钮-导出",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-vehicleListTab-batchEdit",
                    name: "功能按钮-批量编辑",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-vehicleListTab-edit",
                    name: "列表操作-编辑",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-vehicleListTab-delete",
                    name: "列表操作-删除",
                    level: 3,
                  },
                ],
              },
              {
                path: "parkingLotList-detail-merchantTab",
                name: "标签页-商户管理",
                level: 2,
                children: [
                  {
                    path: "parkingLotList-detail-merchantTab-addBtn",
                    name: "功能按钮-新建商户",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-merchantTab-detail",
                    pathName: "merchantDetail",
                    name: "列表操作-查看详情",
                    level: 3,
                    children: [
                      {
                        path: "parkingLotList-detail-merchantTab-detail-info_shopName",
                        name: "基础信息-修改商户名称",
                        level: 3,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-info_shopAddress",
                        name: "基础信息-修改商户位置",
                        level: 3,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-info_contactName",
                        name: "基础信息-修改商户联系人",
                        level: 3,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-info_contactPhone",
                        name: "基础信息-修改联系人手机号",
                        level: 3,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-info_charge",
                        name: "基础信息-修改发放金额",
                        level: 3,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-info_ticketPerMonth",
                        name: "基础信息-修改按月发放金额",
                        level: 3,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-info_averageConsumption",
                        name: "基础信息-修改大众/美团人均消费",
                        level: 3,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-info_rentFree",
                        name: "基础信息-修改是否免租",
                        level: 3,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-info_shopRemarks",
                        name: "基础信息-修改备注",
                        level: 3,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-info_username",
                        name: "基础信息-修改登录名",
                        level: 3,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-info_codeMultiUse",
                        name: "基础信息-修改是否多次领取",
                        level: 3,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-info_codeExpireMinutes",
                        name: "基础信息-修改二维码有效期",
                        level: 3,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-info_password",
                        name: "基础信息-修改登录密码",
                        level: 3,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-coupon_Tab",
                        name: "标签页-优惠券列表",
                        level: 2,
                        children: [
                          {
                            path: "parkingLotList-detail-merchantTab-detail-couponTab-edit",
                            name: "列表操作-编辑",
                            level: 3,
                          },
                          {
                            path: "parkingLotList-detail-merchantTab-detail-couponTab-delete",
                            name: "列表操作-删除",
                            level: 3,
                          },
                          {
                            path: "parkingLotList-detail-merchantTab-detail-couponTab-add",
                            name: "功能按钮-新增优惠券",
                            level: 3,
                          },
                        ],
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-couponPlanList_Tab",
                        name: "标签页-发券计划列表",
                        level: 2,
                        children: [
                          {
                            path: "parkingLotList-detail-merchantTab-detail-couponPlanList_Tab-edit",
                            name: "列表操作-编辑",
                            level: 3,
                          },
                          {
                            path: "parkingLotList-detail-merchantTab-detail-couponPlanList_Tab-delete",
                            name: "列表操作-删除",
                            level: 3,
                          },
                          {
                            path: "parkingLotList-detail-merchantTab-detail-couponPlanList_Tab-add",
                            name: "功能按钮-新增发券计划",
                            level: 3,
                          },
                        ],
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-getCouponList_Tab",
                        name: "标签页-已领优惠券列表",
                        level: 2,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-stopOrder_Tab",
                        name: "标签页-停车订单",
                        level: 2,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-rechargeRecord_Tab",
                        name: "标签页-充值记录",
                        level: 2,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-sendCoupon_Tab",
                        name: "标签页-发券账号",
                        level: 2,
                        children: [
                          {
                            path: "parkingLotList-detail-merchantTab-detail-sendCoupon_Tab-edit",
                            name: "列表操作-编辑",
                            level: 3,
                          },
                          {
                            path: "parkingLotList-detail-merchantTab-detail-sendCoupon_Tab-delete",
                            name: "列表操作-删除",
                            level: 3,
                          },
                          {
                            path: "parkingLotList-detail-merchantTab-detail-sendCoupon_Tab-add",
                            name: "功能按钮-新增账号",
                            level: 3,
                          },
                        ],
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-balanceRecord_Tab",
                        name: "标签页-余额记录",
                        level: 2,
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-vehicleList_Tab",
                        name: "标签页-车辆列表",
                        level: 2,
                        children: [
                          {
                            path: "parkingLotList-detail-merchantTab-detail-vehicleList_Tab-add",
                            name: "功能按钮-新增车辆",
                            level: 3,
                          },
                          {
                            path: "parkingLotList-detail-merchantTab-detail-vehicleList_Tab-import",
                            name: "功能按钮-导入挂单车",
                            level: 3,
                          },
                          {
                            path: "parkingLotList-detail-merchantTab-detail-vehicleList_Tab-edit",
                            name: "列表操作-编辑",
                            level: 3,
                          },
                          {
                            path: "parkingLotList-detail-merchantTab-detail-vehicleList_Tab-delete",
                            name: "列表操作-删除",
                            level: 3,
                          },
                        ],
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-subList_Tab",
                        name: "标签页-子账号列表",
                        level: 2,
                        children: [
                          {
                            path: "parkingLotList-detail-merchantTab-detail-subListTab-edit",
                            name: "列表操作-编辑",
                            level: 3,
                          },
                          {
                            path: "parkingLotList-detail-merchantTab-detail-subListTab-delete",
                            name: "列表操作-删除",
                            level: 3,
                          },
                          {
                            path: "parkingLotList-detail-merchantTab-detail-subListTab-add",
                            name: "功能按钮-新增子账号",
                            level: 3,
                          },
                        ],
                      },
                      {
                        path: "parkingLotList-detail-merchantTab-detail-freeCarOrderList_Tab",
                        name: "标签页-挂单车订单列表",
                        level: 2,
                        children: [
                          {
                            path: "parkingLotList-detail-merchantTab-detail-freeCarOrderList_Tab-export",
                            name: "列表操作-导出报表",
                            level: 3,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "parkingLotList-detail-merchantTab-delete",
                    name: "列表操作-删除",
                    level: 3,
                  },
                ],
              },
              {
                path: "parkingLotList-detail-vehicleAttendanceTab",
                name: "标签页-车辆考勤",
                level: 2,
                children: [
                  {
                    path: "parkingLotList-detail-vehicleAttendanceTab-addBtn",
                    name: "功能按钮-新建考勤",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-vehicleAttendanceTab-detail",
                    name: "列表操作-查看详情",
                    level: 3,
                  },
                ],
              },
              {
                path: "parkingLotList-detail-parkingSpaceTab",
                name: "标签页-车位管理",
                level: 2,
                children: [
                  {
                    path: "parkingLotList-detail-parkingSpaceTab-add",
                    name: "功能按钮-新建",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-parkingSpaceTab-edit",
                    name: "列表操作-编辑",
                    level: 3,
                  },
                  {
                    path: "parkingLotList-detail-parkingSpaceTab-delete",
                    name: "列表操作-删除",
                    level: 3,
                  },
                ],
              },
            ],
          },
          {
            path: "parkingLotList-InFieldCode",
            name: "列表操作-场内码下载",
            level: 3,
          },
        ],
      },
      {
        path: "/parkingGroup/list",
        pathName: "parkingGroup",
        name: "联合停车场",
        level: 1,
        children: [
          {
            path: "parkingGroup-addBtn",
            name: "功能按钮-新建",
            level: 3,
          },
          {
            path: "parkingGroup-detail",
            pathName: "parkingGroupDetail",
            name: "列表操作-查看详情",
            level: 3,
            children: [
              {
                path: "parkingGroup-detail-info_groupName",
                name: "基础信息-修改联合停车场名称",
                level: 3,
              },
              {
                path: "parkingGroup-detail-passagewayTab",
                name: "标签页-出入口列表",
                level: 2,
                children: [
                  {
                    path: "parkingGroup-detail-passagewayTab-detail",
                    name: "列表操作-查看详情",
                    level: 3,
                  },
                  {
                    path: "parkingGroup-detail-passagewayTab-openBtn",
                    name: "列表操作 -开闸",
                    level: 3,
                  },
                ],
              },
              {
                path: "parkingGroup-detail-payRecordTab",
                level: 2,
                name: "标签页-缴费记录",
              },
              {
                path: "parkingGroup-detail-venueVehicleTab",
                level: 2,
                name: "标签页-场内车辆",
                children: [
                  {
                    path: "parkingGroup-detail-venueVehicleTab-forcedOut",
                    name: "列表操作-强制出场",
                    level: 3,
                  },
                ],
              },
              {
                path: "parkingGroup-detail-merchantTab",
                name: "标签页-商户管理",
                level: 2,
                children: [
                  {
                    path: "parkingGroup-detail-merchantTab-addBtn",
                    name: "功能按钮-新建商户",
                    level: 3,
                  },
                  {
                    path: "parkingGroup-detail-merchantTab-detail",
                    pathName: "parkingGroup-merchantDetail",
                    name: "列表操作-查看详情",
                    level: 3,
                    children: [
                      {
                        path: "parkingGroup-detail-merchantTab-detail-info_shopName",
                        name: "基础信息-修改商户名称",
                        level: 3,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-info_shopAddress",
                        name: "基础信息-修改商户位置",
                        level: 3,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-info_contactName",
                        name: "基础信息-修改商户联系人",
                        level: 3,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-info_contactPhone",
                        name: "基础信息-修改联系人手机号",
                        level: 3,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-info_charge",
                        name: "基础信息-修改发放金额",
                        level: 3,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-info_ticketPerMonth",
                        name: "基础信息-修改按月发放金额",
                        level: 3,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-info_averageConsumption",
                        name: "基础信息-修改大众/美团人均消费",
                        level: 3,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-info_rentFree",
                        name: "基础信息-修改是否免租",
                        level: 3,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-info_shopRemarks",
                        name: "基础信息-修改备注",
                        level: 3,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-info_username",
                        name: "基础信息-修改登录名",
                        level: 3,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-info_password",
                        name: "基础信息-修改登录密码",
                        level: 3,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-coupon_Tab",
                        name: "标签页-优惠券列表",
                        level: 2,
                        children: [
                          {
                            path: "parkingGroup-detail-merchantTab-detail-couponTab-edit",
                            name: "列表操作-编辑",
                            level: 3,
                          },
                          {
                            path: "parkingGroup-detail-merchantTab-detail-couponTab-delete",
                            name: "列表操作-删除",
                            level: 3,
                          },
                          {
                            path: "parkingGroup-detail-merchantTab-detail-couponTab-add",
                            name: "功能按钮-新增优惠券",
                            level: 3,
                          },
                        ],
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-getCouponList_Tab",
                        name: "标签页-已领优惠券列表",
                        level: 2,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-stopOrder_Tab",
                        name: "标签页-优惠券订单",
                        level: 2,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-rechargeRecord_Tab",
                        name: "标签页-充值记录",
                        level: 2,
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-sendCoupon_Tab",
                        name: "标签页-发券账号",
                        level: 2,
                        children: [
                          {
                            path: "parkingGroup-detail-merchantTab-detail-sendCoupon_Tab-edit",
                            name: "列表操作-编辑",
                            level: 3,
                          },
                          {
                            path: "parkingGroup-detail-merchantTab-detail-sendCoupon_Tab-delete",
                            name: "列表操作-删除",
                            level: 3,
                          },
                          {
                            path: "parkingGroup-detail-merchantTab-detail-sendCoupon_Tab-add",
                            name: "功能按钮-新增账号",
                            level: 3,
                          },
                        ],
                      },
                      {
                        path: "parkingGroup-detail-merchantTab-detail-balanceRecord_Tab",
                        name: "标签页-余额记录",
                        level: 2,
                      },
                    ],
                  },
                  {
                    path: "parkingGroup-detail-merchantTab-delete",
                    name: "列表操作-删除",
                    level: 3,
                  },
                ],
              },
            ],
          },
          {
            path: "parkingGroup-delBtn",
            name: "列表操作-删除",
            level: 3,
          },
        ],
      },
      {
        path: "/businessCircle/list",
        pathName: "businessCircleList",
        name: "商圈列表",
        level: 1,
        children: [
          {
            path: "businessCircleList-addBtn",
            name: "功能按钮-新建商圈",
            level: 3,
          },
          {
            path: "businessCircleList-editBtn",
            name: "列表操作-编辑",
            level: 3,
          },
          {
            path: "businessCircleList-delBtn",
            name: "列表操作-删除",
            level: 3,
          },
        ],
      },
    ],
  },
  {
    path: "/advertise",
    name: "广告管理",
    img: require("@/assets/images/menu_icon/advertise.png"),
    level: 0,
    children: [
      {
        path: "/advertise/default",
        name: "默认广告",
        pathName: "advertise-default",
        level: 1,
      },
      {
        path: "/advertise/parkingLot",
        name: "停车场广告",
        pathName: "advertise-parkingLot",
        level: 1,
        children: [
          {
            path: "advertise-parkingLot-detail",
            pathName: "advertise-parkingLot-detail",
            name: "列表操作-查看详情",
            level: 3,
          },
        ],
      },
      {
        path: "/advertise/fixed",
        name: "定点投放广告",
        pathName: "advertise-fixed",
        level: 1,
        children: [
          {
            path: "advertise-fixed-add",
            name: "功能按钮-新建",
            level: 3,
          },
          {
            path: "advertise-fixed-edit",
            name: "列表操作-编辑",
            level: 3,
          },
          {
            path: "advertise-fixed-delete",
            name: "列表操作-删除",
            level: 3,
          },
          {
            path: "advertise-fixed-detail",
            pathName: "advertise-fixed-detail",
            name: "列表操作-查看详情",
            level: 3,
          },
        ],
      },
      {
        path: "/advertise/list",
        name: "广告列表",
        level: 2,
        children: [
          {
            path: "advertise-list-add",
            name: "功能按钮-新建广告",
            level: 3,
          },
          {
            path: "advertise-list-verify",
            name: "列表操作-审核",
            level: 3,
          },
          {
            path: "advertise-list-publishStatus0",
            name: "列表操作-下架",
            level: 3,
          },
          {
            path: "advertise-list-publishStatus1",
            name: "列表操作-上架",
            level: 3,
          },
          {
            path: "advertise-list-edit",
            name: "列表操作-编辑",
            level: 3,
          },
          {
            path: "advertise-list-delete",
            name: "列表操作-删除",
            level: 3,
          },
        ],
      },
      {
        path: "/advertise/featuredPush",
        name: "本地特色推送",
        pathName: "advertise-featuredPush",
        level: 1,
      },
      {
        path: "/advertise/promotion",
        name: "图文视频推广",
        pathName: "advertise-promotion",
        level: 1,
        children: [
          {
            path: "advertise-promotion-add",
            name: "功能按钮-新建",
            level: 3,
          },
          {
            path: "advertise-promotion-edit",
            name: "功能按钮-编辑",
            level: 3,
          },
          {
            path: "advertise-promotion-delete",
            name: "功能按钮-删除",
            level: 3,
          },
        ],
      },
    ],
  },
  {
    path: "/user",
    name: "用户管理",
    img: require("@/assets/images/menu_icon/user.png"),
    level: 0,
    children: [
      {
        path: "/averageUser/list",
        pathName: "averageUserList",
        name: "普通用户",
        level: 1,
        children: [
          {
            path: "averageUserList-detail",
            name: "列表操作-查看详情",
            pathName: "averageUserDetail",
            level: 3,
            children: [
              {
                path: "averageUserList-detail-vehicleList",
                name: "标签页-车辆列表",
                level: 2,
                children: [
                  {
                    path: "averageUserList-detail-vehicleList-detail",
                    name: "列表操作-查看详情",
                    level: 3,
                  },
                ],
              },
              {
                path: "averageUserList-detail-orderList",
                name: "订单列表",
                level: 2,
              },
              {
                path: "averageUserList-detail-rechargeList",
                name: "充值列表",
                level: 2,
              },
            ],
          },
        ],
      },
      {
        path: "/systemUser/list",
        pathName: "systemUserList",
        name: "系统用户",
        level: 1,
        children: [
          {
            path: "systemUserList-addSystemUser",
            name: "功能按钮-新建用户",
            level: 3,
          },
          {
            path: "systemUserList-edit",
            name: "列表操作-编辑",
            level: 3,
          },
          {
            path: "systemUserList-delete",
            name: "列表操作-删除",
            level: 3,
          },
        ],
      },
      {
        path: "/advice/list",
        pathName: "adviceList",
        name: "用户反馈",
        level: 1,
        children: [
          {
            path: "advice-addBtn",
            name: "功能按钮-新增反馈",
            level: 3,
          },
          {
            path: "adviceList-solution",
            name: "列表操作-处理",
            level: 3,
          },
          {
            path: "adviceList-detail",
            pathName: "adviceDetail",
            name: "列表操作-查看详情",
            level: 3,
          },
        ],
      },
    ],
  },
  {
    path: "/finance",
    name: "财务管理",
    img: require("@/assets/images/menu_icon/finance.png"),
    level: 0,
    children: [
      {
        path: "/stopOrder/list",
        pathName: "stopOrderList",
        name: "停车订单",
        level: 1,
        children: [
          {
            path: "stopOrderList-export",
            name: "功能按钮-导出报表",
            level: 3,
            children: [
              {
                path: "stopOrderList-export-excel",
                name: "导出类型-Excel",
                level: 3,
              },
              {
                path: "stopOrderList-export-pdf",
                name: "导出类型-PDF",
                level: 3,
              },
            ],
          },
          {
            path: "stopOrderList-exportList",
            name: "功能按钮-导出列表",
            level: 3,
            // children: [
            //   {
            //     path: "stopOrderList-exportList-excel",
            //     name: "导出类型-Excel",
            //     level: 3,
            //   },
            //   {
            //     path: "stopOrderList-exportList-pdf",
            //     name: "导出类型-PDF",
            //     level: 3,
            //   }
            // ]
          },
          // {
          //   path: "stopOrderList-refund",
          //   name: "功能按钮-退款",
          //   level: 3,
          // },
          {
            path: "stopOrderList-requestRefund",
            name: "功能按钮-申请退款",
            level: 3,
          },
          {
            path: "stopOrderList-parkingDetailDialog",
            name: "功能按钮-停车详情弹窗",
            level: 3,
          },
        ],
      },
      {
        path: "/merchantOrder/list",
        pathName: "merchantOrderList",
        name: "商户订单",
        level: 1,
      },
      {
        path: "/walletOrder/list",
        pathName: "walletOrderList",
        name: "钱包订单",
        level: 1,
        children: [
          {
            path: "walletOrder-export",
            name: "功能列表-导出报表",
            level: 3,
          },
        ],
      },
      {
        path: "/refund/list",
        pathName: "refundList",
        name: "退款管理",
        level: 1,
        children: [
          {
            path: "refundList-export",
            name: "功能列表-导出报表",
            level: 3,
          },
          {
            path: "refundList-approval",
            name: "列表操作-审批",
            level: 3,
          },
        ],
      },
      {
        path: "/invoice/list",
        pathName: "invoiceList",
        name: "发票管理",
        level: 1,
        children: [
          {
            path: "adviceList-cancellation",
            name: "列表操作-冲红",
            level: 3,
          },
          {
            path: "adviceList-agree",
            name: "列表操作-同意",
            level: 3,
          },
          {
            path: "adviceList-PDF",
            name: "列表操作-查看PDF",
            level: 3,
          },
        ],
      },
      {
        path: "/invoiceAuth/list",
        pathName: "invoiceAuth",
        name: "发票抬头",
        level: 1,
        children: [
          {
            path: "invoiceAuth-create",
            name: "功能按钮-创建",
            level: 3,
          },
          {
            path: "invoiceAuth-edit",
            name: "列表操作-编辑",
            level: 3,
          },
          {
            path: "invoiceAuth-auth",
            name: "列表操作-授权",
            level: 3,
          },
        ],
      },
    ],
  },
  {
    path: "/logs",
    name: "日志管理",
    img: require("@/assets/images/menu_icon/logs.png"),
    level: 0,
    children: [
      {
        path: "/logs/capture",
        pathName: "captureLog",
        name: "抓拍记录",
        level: 1,
      },
      {
        path: "/logs/strobeControl",
        pathName: "strobeControlLog",
        name: "道闸控制记录",
        level: 1,
      },
      {
        path: "/logs/leaseChange",
        pathName: "leaseChange",
        name: "套餐变动记录",
        level: 1,
      },
    ],
  },
  {
    path: "/system",
    name: "系统设置",
    img: require("@/assets/images/menu_icon/system.png"),
    level: 0,
    children: [
      {
        path: "/system/server/serverList",
        pathName: "serverList",
        name: "服务器列表",
        level: 1,
        children: [
          {
            path: "server-addServer",
            name: "功能按钮-添加服务器",
            level: 3,
          },
          {
            path: "server-editBtn",
            name: "列表操作-编辑",
            level: 3,
          },
          {
            path: "server-delBtn",
            name: "列表操作-删除",
            level: 3,
          },
        ],
      },
      {
        path: "/system/authority/authorityList",
        name: "权限管理",
        pathName: "authorityList",
        level: 1,
        children: [
          {
            path: "authority-addAuthority",
            name: "功能按钮-新增权限",
            level: 3,
          },
          {
            path: "authority-editBtn",
            name: "列表操作-编辑",
            level: 3,
          },
          {
            path: "authority-delBtn",
            name: "列表操作-删除",
            level: 3,
          },
        ],
      },
      {
        path: "/device/register/list",
        pathName: "deviceRegister",
        name: "设备主动注册",
        level: 1,
        children: [
          {
            path: "deviceRegister-issueInfo",
            name: "列表操作-下发配置",
            level: 3,
          },
          {
            path: "deviceRegister-checkMonitor",
            name: "列表操作-查看监控",
            level: 3,
          },
          {
            path: "deviceRegister-edit",
            name: "列表操作-编辑",
            level: 3,
          },
          {
            path: "deviceRegister-delete",
            name: "列表操作-删除",
            level: 3,
          },
        ],
      },
      {
        path: "/device/remoteIp/list",
        pathName: "deviceRemoteIp",
        name: "注册IP列表",
        level: 1,
        children: [
          {
            path: "remoteIp-create",
            name: "功能按钮-新建IP",
            level: 3,
          },
          {
            path: "remoteIp-edit",
            name: "列表操作-编辑",
            level: 3,
          },
          {
            path: "remoteIp-delete",
            name: "列表操作-删除",
            level: 3,
          },
        ],
      },
    ],
  },
];
